import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import * as rules from "vee-validate/dist/rules";
import * as customRules from "@/plugins/vee-validate-custom-rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

Object.keys(customRules).forEach((rule) => {
  extend(rule, customRules[rule]);
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// 固有メッセージの変更
ja.messages = {
  ...ja.messages,
  required: "{_field_}は必須項目です。 Required input.",
  email: "{_field_}は有効なメールアドレスではありません。 Invalid form.",
  length: "{_field_}は{length}文字で入力してください。",
  min: "{_field_}は{length}文字以上で入力してください。 {length} or more characteres are required.",
  max: "{_field_}は{length}文字以内で入力してください。 {length} or less characteres are drequired.",
  confirmed: "パスワードが一致しません。 Passwords do not match.",
  numeric: "{_field_}には数字のみを入力してください。 Input only numbers for a phone number."
};

localize("ja", ja);

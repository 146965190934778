<template>
  <v-btn
    class="text-center"
    :class="[$style['base-btn'], disabled ? 'disabled' : '']"
    :disabled="disabled"
    :color="color"
    rounded
    block
    large
    :outlined="outlined"
    @click="($event) => $emit('click', $event)"
    >{{ label }}</v-btn
  >
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "ベースボタン"
    },
    color: {
      type: String,
      default: "#FD6955"
    },
    fontColor: {
      type: String,
      default: "white"
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module lang="scss">
.base-btn {
  &:disabled {
    opacity: 0.6;
    background-color: #ccc !important;
  }
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}
</style>

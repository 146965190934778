import { options } from "./base";

export const common = {
  namespaced: true,
  modules: {
    categories: {
      namespaced: true,
      modules: {
        options: options("categories")
      }
    },
  }
};

import { clientWithoutAuth } from "@/entries/front/axios";
import { entity, setOptionRefreshTrigger, clearDirty } from "./base";
import utility, { concatModules } from "@/utility";
import router from '@/entries/front/router';

const initialEntity = {
  next: null,
  back: null,
  confirm: null,
}

const appBarModule = {
  mutations: {
    setButtons(state, payload) {
      state.entity = { ...state.entity, ...payload };
    }
  },
  actions: {
    goNext({commit, getters, state}) {
      return new Promise((resolve, reject) => {
        const { apiUrl, pathName, path } = getters.entity.next;
        if (apiUrl) {
          clientWithoutAuth
            .post(apiUrl, {
              params
            })
            .then((response) => {
              commit("succeeded", {
                options: response.data.data
              });

              if (pathName) {
                router.push({ name: pathName });
              } else if (path) {
                router.push({ path });
              }

              resolve();
            })
            .catch((error) => {
              commit("failed");

              reject(error);
            });
        } else {
          if (pathName) {
            router.push({ name: pathName });
          } else if (path) {
            router.push({ path });
          }

          resolve();
        }
      });
    },
    goBack({commit, getters, state}) {
      return new Promise((resolve, reject) => {
        const { apiUrl, pathName, path } = getters.entity.back;
        if (apiUrl) {
          clientWithoutAuth
            .post(apiUrl, {
              params
            })
            .then((response) => {
              commit("succeeded", {
                options: response.data.data
              });

              if (pathName) {
                router.push({ name: pathName });
              } else if (path) {
                router.push({ path });
              }

              resolve();
            })
            .catch((error) => {
              commit("failed");

              reject(error);
            });
        } else {
          if (pathName) {
            router.push({ name: pathName });
          } else if (path) {
            router.push({ path });
          }

          resolve();
        }
      });
    }
  },
};

const entityModule = entity("appBar", initialEntity);

export const appBar = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, appBarModule)
  }
};

let utility = {
  isIPad() {
    return (
      new RegExp("(iPhone|iPad|Macintosh).*Version/1[1-9].*Safari/").test(
        window.navigator.userAgent
      ) && document.ontouchstart !== undefined
    );
  }
};

export default utility;

/**
 * store module 結合
 */
export const concatModules = (
  moduleBase,
  moduleOverwrite,
  namespaced = true
) => {
  return {
    namespaced: namespaced,
    state: {
      ...moduleBase.state,
      ...moduleOverwrite.state
    },
    mutations: {
      ...moduleBase.mutations,
      ...moduleOverwrite.mutations
    },
    actions: {
      ...moduleBase.actions,
      ...moduleOverwrite.actions
    },
    getters: {
      ...moduleBase.getters,
      ...moduleOverwrite.getters
    }
  };
};

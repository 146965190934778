import { clientWithoutAuth } from "@/entries/front/axios";
import { entity, setOptionRefreshTrigger, clearDirty } from "./base";
import utility, { concatModules } from "@/utility";

const initialEntity = {
  name: "",
  user_type: "",
  employee_num: "",
  branch_id: "",
  organization: "",
  group_id: "",
  post: "",
  email: "",
  email_confirmation: "",
  answered: null
};

const userModule = {
  actions: {
    store({ commit }, { storedEntity, formKeys = [] }) {
      const store = this;

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          .post(`/users/registration`, storedEntity)
          .then(() => {
            commit("processed");

            setOptionRefreshTrigger("users", store);

            clearDirty(commit, formKeys);

            utility.$message({
              type: "success",
              message: "登録しました",
              showClose: true,
              customClass: "el-message-content-position"
            });

            resolve();
          })
          .catch((error) => {
            commit("processed");
            commit("failed", { error });

            reject(error);
          });
      });
    },
    emailCheck({ commit }, { email, excludedId = undefined }) {
      return new Promise((resolve, reject) => {
        clientWithoutAuth
          .post(`/users/emailcheck`, {
            email,
            excludedId
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("failed", { error });

            reject(error);
          });
      });
    },
    checkAnswered({ commit }, { id }) {
      const store = this;
      return new Promise((resolve, reject) => {
        store.$axios
          .get(`/users/answered/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            commit("failed", { error });

            reject(error);
          });
      });
    }
  },
  getters: {}
};

const entityModule = entity("users", initialEntity);

export const users = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, userModule)
  }
};

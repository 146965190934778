import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from "@/entries/front/axios";
import { form } from "./form";
import { auth } from "./auth";
import { appBar } from "./appbar";
import { toolbar } from "./toolbar";
import { filters } from "./filters";
import { common } from "./common";
import { reissuePassword } from "./reissuePassword";
import { users } from "./users";
import { accounts } from "./accounts";
import { inquiry } from "./inquiry";
import { done } from "./done";

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_FRONT_NAME || "VUE_APP_FRONT_NAME",
      paths: [
        "auth.accessToken",
        "auth.refreshToken",
        "auth.expiresIn",
        "auth.accessTokenClaims"
      ],
      storage: window.sessionStorage
    })
  ],
  modules: {
    form,
    auth,
    appBar,
    toolbar,
    filters,
    common,
    reissuePassword,
    users,
    accounts,
    inquiry,
    done
  }
});

store.$axios = axios;

export default store;

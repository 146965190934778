const conditions = [
  {
    cond: "mt",
    text: "部分一致する",
    useValue: true
  },
  {
    cond: "eq",
    text: "等しい",
    useValue: true
  },
  {
    cond: "neq",
    text: "等しくない",
    useValue: true
  },
  {
    cond: "sw",
    text: "〜で始まる",
    useValue: true
  },
  {
    cond: "ew",
    text: "〜で終わる",
    useValue: true
  },
  {
    cond: "ex",
    text: "値がある",
    useValue: false
  },
  {
    cond: "nex",
    text: "値がない",
    useValue: false
  },
  {
    cond: "lt",
    text: "未満",
    useValue: true
  },
  {
    cond: "lte",
    text: "以下",
    useValue: true
  },
  {
    cond: "gt",
    text: "より大きい",
    useValue: true
  },
  {
    cond: "gte",
    text: "以上",
    useValue: true
  },
  {
    cond: "ltd",
    text: "前である",
    useValue: true
  },
  {
    cond: "gtd",
    text: "後である",
    useValue: true
  }
];

const conditionsOf = {
  string: ["mt", "eq", "neq", "sw", "ew", "ex", "nex"],
  number: ["ex", "nex", "eq", "lt", "lte", "gt", "gte"],
  date: ["ex", "nex", "eq", "ltd", "gtd"],
  boolean: ["ex", "nex", "eq"]
};

export const filters = {
  namespaced: true,
  state: {
    open: false,
    shouldFetch: false,
    filters: [],
    backup: ""
  },
  mutations: {
    initialize(state) {
      state.open = false;
      state.shouldFetch = false;
      state.filters = [];
      state.backup = "";
    },
    open(state) {
      state.open = true;
    },
    add(state, { field, cond, value }) {
      state.filters = [
        ...state.filters,
        {
          field,
          cond,
          value,
          index: state.filters.length
        }
      ];
    },
    remove(state, { index }) {
      state.filters = [
        ...state.filters
          .filter(filter => filter.index !== index)
          .map((filter, index) => ({
            ...filter,
            index
          }))
      ];
    },
    apply(state) {
      state.shouldFetch = true;
      state.backup =
        state.filters.length > 0 ? JSON.stringify(state.filters) : "";
      state.open = false;
    },
    clearAll(state) {
      state.shouldFetch = true;
      state.filters = [];
      state.backup = "";
      state.open = false;
    },
    cancel(state) {
      state.shouldFetch = false;
      state.filters = state.backup ? JSON.parse(state.backup) : [];
      state.open = false;
    },
    fetched(state) {
      state.shouldFetch = false;
    }
  },
  actions: {
    initialize({ commit }) {
      commit("initialize");
    }
  },
  getters: {
    open(state) {
      return state.open;
    },
    shouldFetch(state) {
      return state.shouldFetch;
    },
    filters(state) {
      return state.filters || [];
    },
    conditions() {
      return type => {
        return conditions.reduce((previous, current) => {
          if ((conditionsOf[type] || []).indexOf(current.cond) >= 0) {
            previous.push(current);
          }

          return previous;
        }, []);
      };
    },
    allConditions() {
      return conditions;
    },
    filtered(state) {
      return !!state.backup;
    }
  }
};

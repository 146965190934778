<template>
  <div class="text-center" :class="$style.anchor" @click="click">
    {{ label }} <v-icon>mdi-chevron-right</v-icon>
  </div>
</template>

<script>

export default {
  props: {
    name: {
      type: String
    },
    path: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    click(e) {
      let pushObject = null;
      if (this.name || this.path) {
        if (this.name) {
          pushObject = { name: this.name }
        } else if (this.path) {
          pushObject = { path: this.path }
        }

        this.$router.push(pushObject);
      } else {
        this.$emit('click', e);
      }
    }
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.anchor {
  text-decoration-line: none;
  color: $base-font-color;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
}
</style>

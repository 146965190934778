import router from "@/entries/front/router";
import { clientWithoutAuth } from "@/entries/front/axios";
import { entity, setOptionRefreshTrigger, clearDirty } from "./base";
import utility, { concatModules } from "@/utility";

const initialEntity = {
  name: "",
  mailAddress: "",
  contents: ""
};

const inquiryModule = {
  mutations: {
    setEntity(state, { entity }) {
      state.entity = { ...state.entity, ...entity };
    }
  },
  actions: {
    /**
     * お問い合わせデータの送信
     */
    inquiry({ commit }, { name, mailAddress, contents, accountId }) {
      const data = {
        accountId,
        name,
        mailAddress,
        contents
      };

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          .post(`/inquiry`, data)
          .then((response) => {
            commit("processed");

            utility.$message({
              type: "success",
              message: "送信しました",
              showClose: true,
              customClass: "el-message-content-position"
            });
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    }
  },
  getters: {}
};

const entityModule = entity("inquiry", initialEntity);

export const inquiry = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, inquiryModule)
  }
};

<template>
  <v-card width="400" min-width="300">
    <v-card-text class="fill-height"
      ><h4>
        <v-icon color="red darken-2">mdi-alert-circle</v-icon>
        {{ message }}
      </h4></v-card-text
    >
    <v-card-actions class="justify-end"
      ><v-btn text color="warning" @click="($event) => $emit('click', $event)"
        ><v-icon>mdi-arrow-left-bold-box</v-icon>戻る</v-btn
      ></v-card-actions
    >
  </v-card>
</template>

<script>
export default {
  props: {
    message: {
      type: String
    }
  }
};
</script>

import Vue from "vue";
import Element, {
  Table,
  MessageBox,
  Notification,
  Message,
  Loading
} from "element-ui";
import lang from "element-ui/lib/locale/lang/ja";
import locale from "element-ui/lib/locale";
import utility from "@/utility";

locale.use(lang);

Vue.use(Element, { locale });

utility.$msgbox = MessageBox;
utility.$alert = MessageBox.alert;
utility.$confirm = MessageBox.confirm;
utility.$prompt = MessageBox.prompt;
utility.$notify = Notification;
utility.$message = Message;
utility.$loading = Loading.service;

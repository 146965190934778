<template>
  <v-app-bar
    app
    height="64"
    :elevation="0"
    color="#f68e62"
    :class="$style['app-header']"
  >
    <v-row>
      <div
        class="d-flex flex-row justify-center align-center mx-auto"
        :class="$style['app-header-body']"
      >
        <v-toolbar-title class="d-flex align-center">
          <img
            :src="logoSrc"
            :srcset="logoSrcSet"
            @click="onClickLogo"
            :class="$style['main-logo']"
          />
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </div>
        <v-col cols="6">
          <v-btn v-if="button.back && button.back.show" text color="#fff" @click="goBack">
            <v-icon>mdi-chevron-left</v-icon>戻る
          </v-btn>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn v-if="button.back && button.next.show" text color="#fff" @click="goNext">
            次へ<v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      <v-progress-linear
        :class="$style['progress']"
        :active="processing"
        indeterminate
        color="#13874D"
      ></v-progress-linear>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    logoSrc() {
      return "";
    },
    logoSrcSet() {
      return "";
    },
    authenticated() {
      return this.$store.getters[`auth/authenticated`]();
    },
    userType() {
      return this.$store.getters[`auth/userType`];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    },
    button() {
      return this.$store.getters[`appBar/entity/entity`];
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {
    onClickLogo() {
      if (this.authenticated) {
        this.$router.push({ name: "menu" }).catch(() => {});
      } else {
        return;
      }
    },
    logout() {
      this.$store.dispatch(`auth/logout`);
    },
    goNext(e) {
      this.$store.dispatch(`appBar/entity/goNext`);
    },
    goBack(e) {
      this.$store.dispatch(`appBar/entity/goBack`);
    }
  },
  created() {
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";

.app-header {
  & > div {
    padding: 0 !important;
  }
}

.app-header-body {
  width: 100% !important;
}

.progress {
  position: absolute !important;
  bottom: 0;
  width: 100%;
}

.main-logo {
  &:hover {
    cursor: pointer !important;
  }
}

.header-desc {
  font-size: 12px;
  margin: 2px 30px 0;
  padding: 6px 20px;
  border: 2px solid #ff0000 !important;
  align-self: baseline;
}
</style>

export const toolbar = {
  namespaced: true,
  state: {
    sideMenuCollapsed: false
  },
  mutations: {
    toggleSideMenu(state) {
      state.sideMenuCollapsed = !state.sideMenuCollapsed;
    }
  },
  actions: {},
  getters: {
    sideMenuCollapsed(state) {
      return state.sideMenuCollapsed;
    }
  }
};

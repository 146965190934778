<template>
  <v-app>
    <v-main :class="$style['main']">
      <router-view />
    </v-main>
    <ReauthenticationDialog />
  </v-app>
</template>

<script>
import ReauthenticationDialog from "@/entries/front/components/dialogs/reauthentication";

export default {
  components: {
    ReauthenticationDialog
  }
};
</script>

<style module lang="scss">
.main {
  background-color: #f3f5f8 !important;
}
</style>

<style lang="scss">
.img-btn {
  cursor: pointer !important;
  &:hover {
    opacity: 0.8 !important;
  }
}
</style>

import { clientWithoutAuth } from "@/entries/front/axios";
import moment from "moment";
import utility from "@/utility";
import router from "@/entries/front/router";

const initialEntity = {
  mailAddress: "",
  password: "",
  password_confirm: "",
  termsOfServiceChecked: false,
  privacyPolicyChecked: false,
  code: "",
  phone: "",
  lastName: "",
  firstName: ""
};

export const auth = {
  namespaced: true,
  state: {
    entity: null,
    accessToken: "",
    refreshToken: "",
    expiresIn: 0,
    accessTokenClaims: {},
    // iss
    // aud
    // jti
    // iat
    // nbf
    // exp
    // uid
    // user_type
    processing: false,
    reAuthorizing: false,
    error: false,
    status: null,
    errorMessages: {}
  },
  mutations: {
    initialize(state) {
      state.entity = { ...initialEntity };
      state.processing = false;
      state.error = false;
      state.errorMessages = [];
    },
    succeeded(state, { accessToken, refreshToken, expiresIn }) {
      const accessTokenClaims = JSON.parse(
        window.atob(accessToken.split(".")[1])
      );

      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.expiresIn = expiresIn;
      state.accessTokenClaims = { ...accessTokenClaims };
      state.processing = false;
      state.error = false;
    },
    failedLogin(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.expiresIn = 0;
      state.accessTokenClaims = {};
      state.processing = false;
      state.error = true;
    },
    failedRefresh(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.expiresIn = 0;
      state.accessTokenClaims = {};
      state.processing = false;
      state.error = false;
    },
    failed(state, { error }) {
      state.processing = false;

      let status = null;
      let errorMessages = {};

      if (error.response) {
        status = error.response.status;
      } else {
        status = error.status || null;
        errorMessages = {};
      }

      switch (status) {
        case 422:
          state.error = true;
          state.errorMessages = error.response.data;
          break;

        case 401:
        case 500:
          state.error = true;
          state.errorMessages = { message: error.response.data.message };
          break;

        default:
          state.error = true;
          break;
      }
    },
    clear(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.expiresIn = 0;
      state.accessTokenClaims = {};
      state.processing = false;
      state.reAuthorizing = false;
      state.error = false;
    },
    processing(state) {
      state.processing = true;
    },
    reAuthorize(state) {
      state.reAuthorizing = true;
    },
    reAuthorized(state) {
      state.reAuthorizing = false;
    },
    setEntity(state, { entity }) {
      state.entity = { ...state.entity, ...entity };
    }
  },
  actions: {
    /**
     * tokenの有効状態チェック
     */
    validate({ commit, dispatch, getters }) {
      if (getters.authenticated) {
        if (getters.accessTokenIsExpired()) {
          // アクセストークン期限切れてる
          if (getters.canRefresh()) {
            // リフレッシュする
            return dispatch("refresh");
          } else {
            // リフレッシュトークンも期限切れてる
            commit("clear");
          }
        } else {
          // 有効なトークンが残っているので何もしない
        }
      } else {
        // 認証されていない
        commit("clear");
      }

      return Promise.resolve();
    },
    /**
     * 認証
     */
    login({ commit }, { mailAddress, password }) {
      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          .post("/users/auth", {
            mailAddress,
            password
          })
          .then((response) => {
            commit("succeeded", {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              expiresIn: response.data.expiresIn
            });

            commit("initialize");

            resolve();
          })
          .catch((error) => {
            commit("failedLogin");
            commit("failed", { error });
            reject();
          });
      });
    },
    /**
     * tokenリフレッシュ
     */
    refresh({ commit, state }) {
      commit("processing");

      return new Promise((resolve) => {
        clientWithoutAuth
          .put("/auth", {
            refreshToken: state.refreshToken
          })
          .then((response) => {
            commit("succeeded", {
              accessToken: response.data.accessToken,
              refreshToken: response.data.refreshToken,
              expiresIn: response.data.expiresIn
            });

            resolve(response.data.accessToken);
          })
          .catch(() => {
            commit("failedRefresh");
            resolve("");
          });
      });
    }
    /**
     * ログアウト
     */
    // logout({ commit, rootGetters }, { accountId }) {
    //   const logout = () => {
    //     commit("processing");

    //     clientWithoutAuth
    //       .delete(`/accounts/signout/${accountId}`)
    //       .then(() => {
    //         commit("clear");
    //         this.commit("done/displayableTrue");
    //         router.push({
    //           name: "done-index",
    //           params: {
    //             message: "サインアウトしました。 Signed out successfully."
    //           }
    //         });
    //       })
    //       .catch(() => {
    //         commit("clear");
    //         // this.commit("done/displayableTrue");
    //         // router.push({
    //         //   name: "done-index",
    //         //   params: {
    //         //     message: "サインアウトしました。 Signed out successfully."
    //         //   }
    //         // });
    //       });
    //   };

    //   if (rootGetters["form/isAnyDirty"]) {
    //     utility
    //       .$confirm("入力内容を破棄します。よろしいですか。", "確認", {
    //         confirmButtonText: "OK",
    //         cancelButtonText: "Cancel",
    //         type: "warning",
    //         center: true
    //       })
    //       .then(() => {
    //         commit("form/clearDirty", null, { root: true });
    //         logout();
    //       })
    //       .catch(() => {});
    //   } else {
    //     logout();
    //   }
    // }
  },
  getters: {
    // 認証状態
    authenticated(state, getters) {
      return () => {
        if (state.accessToken && getters["getFuid"]()) {
          if (getters["accessTokenIsExpired"]()) {
            if (getters["canRefresh"]()) {
              return true;
            }
          } else {
            return true;
          }
        }

        return false;
      };
    },
    // 認可(アクセス権限)
    authorized() {
      // TODO:
      return true;
    },
    accessToken(state) {
      return state.accessToken;
    },
    accessTokenIsExpired(state) {
      return () => state.accessTokenClaims.exp - 10 < moment().unix();
    },
    canRefresh(state) {
      return () => moment().unix() < state.expiresIn - 10;
    },
    processing(state) {
      return !!state.processing;
    },
    isError(state) {
      return !!state.error;
    },
    errorMessages(state) {
      return state.errorMessages;
    },
    reAuthorizing(state) {
      return !!state.reAuthorizing;
    },
    userId(state) {
      return state.accessTokenClaims.f_uid || "";
    },
    userType(state) {
      return state.accessTokenClaims.user_type || "";
    },
    getFuid(state) {
      return () => state.accessTokenClaims.f_uid;
    },
    initialEntity() {
      return { ...initialEntity };
    },
    entity(state) {
      return state.entity || {};
    }
  }
};

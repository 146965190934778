import axios from "axios";
import AsyncLock from "async-lock";
import store from "@/entries/front/store";
import utility from "@/utility";
import router from "@/entries/front/router";

/**
 * cookie送信
 */
axios.defaults.withCredentials = true;

const withAuth = axios.create();
const withoutAuth = axios.create();
const lock = new AsyncLock();

/**
 * Authorizationヘッダ付与
 */
withAuth.interceptors.request.use(async (config) => {
  const accessToken = await refreshTokenIfExpired();

  if (accessToken) {
    config.headers["Custom-Authorization"] = `Bearer ${accessToken}`;
  } else {
    // 再認証
    store.commit("auth/reAuthorize");

    const error = new Error("Access token doesn't exist.");
    error.status = 401;

    throw error;
  }

  return config;
});

const refreshTokenIfExpired = async () => {
  let accessToken = "";

  await lock
    .acquire("refresh", async () => {
      if (!store.getters["auth/authenticated"]()) {
        return;
      }

      if (store.getters["auth/accessTokenIsExpired"]()) {
        if (store.getters["auth/canRefresh"]()) {
          accessToken = await store.dispatch("auth/refresh");
        } else {
          accessToken = "";
        }
      } else {
        accessToken = store.getters["auth/accessToken"];
      }
    })
    .catch(() => {});

  return accessToken;
};

/**
 * 例外処理
 */
withAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status = parseInt(error.response.status);

      if (status < 500) {
        // ユーザエラー
        switch (status) {

          case 422:
            // 検証エラー
            break;

          default:
            utility.$alert(error.response.data.message || error.message, "", {
              confirmButtonText: "OK",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: "error"
            });
            break;
        }
      } else {
        // システムエラー
        switch (status) {
          default:
            utility.$alert(error.response.data.message || error.message, "", {
              confirmButtonText: "OK",
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: "error"
            });
            break;
        }
      }
    }

    return Promise.reject(error);
  }
);

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const getPath = (path) => {
  return `${process.env.VUE_APP_BASE_URL}/api/${path.replace(/^\//, "")}`;
};

const getApi = (path, options = {}) => {
  return withAuth.get(getPath(path), {
    ...options,
    headers: defaultHeaders
  });
};

const postApi = (path, body, options = {}) => {
  return withAuth.post(getPath(path), body, {
    ...options,
    headers: defaultHeaders
  });
};

const putApi = (path, body, options = {}) => {
  return withAuth.put(getPath(path), body, {
    ...options,
    headers: defaultHeaders
  });
};

const deleteApi = (path, options = {}) => {
  return withAuth.delete(getPath(path), {
    ...options,
    headers: defaultHeaders
  });
};

const postUploadApi = (path, body, options = {}) => {
  return withAuth.post(getPath(path), body, {
    ...options,
    headers: {
      ...defaultHeaders,
      "Content-Type": "multipart/form-data"
    }
  });
};

const putUploadApi = (path, body, options = {}) => {
  return withAuth.post(getPath(path), body, {
    ...options,
    headers: {
      ...defaultHeaders,
      "Content-Type": "multipart/form-data",
      "X-HTTP-Method-Override": "PUT"
    }
  });
};

export default {
  get: getApi,
  post: postApi,
  put: putApi,
  delete: deleteApi,
  postUpload: postUploadApi,
  putUpload: putUploadApi
};

export const clientWithoutAuth = {
  get: (path, body, options = {}) => {
    return withoutAuth.get(getPath(path), body, {
      ...options,
      headers: defaultHeaders
    });
  },
  post: (path, body, options = {}) => {
    return withoutAuth.post(getPath(path), body, {
      ...options,
      headers: defaultHeaders
    });
  },
  put: (path, body, options = {}) => {
    return withoutAuth.put(getPath(path), body, {
      ...options,
      headers: defaultHeaders
    });
  },
  delete: (path, options = {}) => {
    return withoutAuth.delete(getPath(path), {
      ...options,
      headers: defaultHeaders
    });
  }
};

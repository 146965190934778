import { entity, clearDirty } from "./base";
import { clientWithoutAuth } from "./../axios";
import utility, { concatModules } from "@/utility";

const initialEntity = {
  email: ""
};

const reissuePasswordModule = {
  mutations: {
    succeededReissuingPassword(state) {
      state.error = false;
      state.processing = false;
    },
    setEntity(state, { entity }) {
      state.entity = { ...state.entity, ...entity };
    },
    failed(state, { error }) {
      state.processing = false;

      let status = null;
      let errorMessages = {};

      if (error.response) {
        status = error.response.status;
      } else {
        status = error.status || null;
        errorMessages = {};
      }

      switch (status) {
        case 422:
          state.error = true;
          state.errorMessages = error.response.data;
          break;

        case 401:
          // 何もしない
          break;

        case 500:
          state.error = true;
          state.errorMessages = { message: error.response.data.message };
          break;

        default:
          state.error = true;
          break;
      }
    }
  },
  actions: {
    reissue({ commit, state }, { email }) {
      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth // axios
          .put(`/users/registration`, {
            email
          })
          .then((response) => {
            commit("processed");

            commit("succeeded", {
              entity: { ...initialEntity }
            });

            resolve();
          })
          .catch((error) => {
            commit("processed");
            commit("failed", { error });

            reject(error);
          });
      });
    }
  }
};

const entityModule = entity("reissuePassword", initialEntity);

export const reissuePassword = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, reissuePasswordModule)
  }
};

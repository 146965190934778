export const form = {
  namespaced: true,
  state: {
    isAnyDirty: false
  },
  mutations: {
    changeDirty(state, payload) {
      let isAnyDirty = {};

      payload.forEach(value => {
        isAnyDirty = {
          ...isAnyDirty,
          [value.key]: value.isDirty
        };
      });

      state.isAnyDirty = {
        ...(state.isAnyDirty || {}),
        ...isAnyDirty
      };
    },
    clearDirty(state) {
      state.isAnyDirty = false;
    }
  },
  actions: {},
  getters: {
    isAnyDirty(state) {
      return state.isAnyDirty
        ? Object.values(state.isAnyDirty).some(value => value)
        : false;
    },
    isDirty(state) {
      return key => {
        return state.isAnyDirty ? state.isAnyDirty[key] : false;
      };
    }
  }
};

<template>
  <v-card
    class="mx-auto pa-1 navigation"
    :rounded="'0'"
    :elevation="0"
    max-width="900"
  >
    <v-row class="flex-row" dense>
      <template v-for="(item, index) in items">
        <v-col :key="index" class="pa-0 align-center justify-center nav-col">
          <v-card
            class="navigation-item mx-0"
            flat
            link
            :rounded="'0'"
            :to="item.path"
            :active-class="active"
          >
            <v-card-text class="pa-0 text-center">
              {{ item.text }}
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
import utility from "@/utility";
export default {
  data() {
    return {
      title: this.$config.title,
    };
  },
  computed: {
    collapsed() {
      return this.$store.getters["toolbar/sideMenuCollapsed"];
    },
    active() {
      return "active";
    },
    userId() {
      return this.$store.getters[`auth/userId`];
    },
    items() {
      return [
        {
          path: "/login",
          text: "ログイン",
        },
      ];
    },
  },
  methods: {
  }
};
</script>

<style module lang="scss"></style>

<style scoped lang="scss">
.active {
  position: relative;
  &:after {
    content: "";
    margin-top: 4px;
    width: 100%;
    height: 6px;
    position: absolute;
    font-weight: bold;
    opacity: 0.8;
    background: linear-gradient(
        90deg,
        rgba(213, 223, 70, 1) 0%,
        rgba(148, 202, 129, 1) 90%,
        rgba(61, 173, 207, 1) 100%
      )
      left bottom #fff no-repeat;
    background-size: 100% 6px;
  }
}
.nav-col {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  &:not(:first-child) {
    border-left-width: 0;
  }
}
</style>

<style lang="scss">
.el-popup-parent--hidden {
  padding-right: 0 !important;
}
</style>

export const done = {
  namespaced: true,
  state: {
    isDisplayable: false
  },
  mutations: {
    displayableTrue(state, payload) {
      state.isDisplayable = true;
    },
    displayableFalse(state, payload) {
      state.isDisplayable = false;
    }
  },
  actions: {},
  getters: {
    isDisplayable(state) {
      return state.isDisplayable;
    }
  }
};

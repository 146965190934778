import Vue from "vue";

const config = {
  title: "JAM ID",
  color: {
    primary: '#1992d2'
  }
};

Vue.prototype.$config = config;

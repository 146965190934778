import { render, staticRenderFns } from "./signin-footer.vue?vue&type=template&id=cff76534&scoped=true&"
import script from "./signin-footer.vue?vue&type=script&lang=js&"
export * from "./signin-footer.vue?vue&type=script&lang=js&"
import style0 from "./signin-footer.vue?vue&type=style&index=0&module=true&lang=scss&"
import style1 from "./signin-footer.vue?vue&type=style&index=1&id=cff76534&scoped=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "cff76534",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VFooter})

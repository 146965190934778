<template>
  <v-footer color="#DEE1E8">
    <v-card class="mx-auto" color="#DEE1E8" elevation="0">
      <v-card-title class="justify-center">
        <div>他のアカウントを使用する</div>
      </v-card-title>
      <v-card-text>
        <v-btn block color="#fff">Googleでサインアップ</v-btn>
        <v-btn block color="black" class="white--text"
          >Appleでサインアップ</v-btn
        >
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    logoSrc() {
      return "";
    }
  },
  methods: {}
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
$background-color: #f3f7f8;

.container {
  max-width: $app-width;
}
.footer {
  font-size: $font-size-root;
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: $background-color !important;
}
.footer-row {
  max-width: $app-width !important;
  margin: 0 auto !important;
}
</style>

<style scoped lang="scss">
$background-color: #f3f7f8;
.nav-text {
  background-color: $background-color !important;
  img {
    &:hover {
      cursor: pointer !important;
    }
  }
}
.footer-nav-col {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  &:first-child {
    border-left-width: 0;
  }
  &:last-child {
    border-right-width: 0;
  }
}
</style>

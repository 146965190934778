import router from "@/entries/front/router";
import utility from "@/utility";
import { clientWithoutAuth } from "@/entries/front/axios";

export const clearDirty = (commit, formKeys = []) => {
  if (formKeys.length > 0) {
    commit(
      "form/changeDirty",
      formKeys.map((formKey) => {
        return {
          key: formKey,
          isDirty: false
        };
      }),
      {
        root: true
      }
    );
  } else {
    commit("form/clearDirty", null, { root: true });
  }
};

export const setOptionRefreshTrigger = (name, store) => {
  if (store.state[name].options) {
    store.commit(`${name}/options/setRefreshTrigger`, {
      root: true
    });
  }
};

export const options = (name) => {
  return {
    namespaced: true,
    state: {
      options: [],
      loading: false,
      loaded: false
    },
    mutations: {
      loading(state) {
        state.loading = true;
      },
      succeeded(state, { options }) {
        state.options = options;
        state.loading = false;
        state.loaded = true;
      },
      failed(state) {
        state.loading = false;
        state.loaded = false;
      },
      setRefreshTrigger(state) {
        state.loaded = false;
      }
    },
    actions: {
      index({ commit, getters }, params = undefined) {
        // if (getters.isLoaded || getters.isLoading) {
        //   return Promise.resolve();
        // }

        commit("loading");

        return new Promise((resolve, reject) => {
          clientWithoutAuth
            .get(`/options/${name}`, {
              params
            })
            .then((response) => {
              commit("succeeded", {
                options: response.data.data
              });

              resolve();
            })
            .catch((error) => {
              commit("failed");

              reject(error);
            });
        });
      }
    },
    getters: {
      options(state) {
        return state.options || [];
      },
      isLoading(state) {
        return state.loading;
      },
      isLoaded(state) {
        return state.loaded;
      }
    }
  };
};

export const list = (name) => {
  return {
    namespaced: true,
    state: {
      entities: [],
      total: 0,
      page: undefined,
      perPage: undefined,
      sort: {},
      loading: false,
      loaded: false,
      processing: false,
      error: false,
      errorMessages: []
    },
    mutations: {
      loading(state) {
        state.loading = true;
      },
      succeeded(state, { entities, total, page }) {
        state.entities = entities;
        state.total = total;
        state.page = page;
        state.loading = false;
        state.loaded = true;
        state.error = false;
      },
      failed(state, { error }) {
        state.loading = false;
        state.loaded = true;

        let status = null;
        let errorMessages = [];

        if (error.response) {
          status = error.response.status;
          errorMessages = error.response.data;
        } else {
          status = error.status || null;
          errorMessages = [];
        }

        switch (status) {
          case 422:
            state.errorMessages = errorMessages;
            break;

          case 401:
            // 何もしない
            break;

          default:
            state.error = true;
            break;
        }
      },
      processing(state) {
        state.processing = true;
      },
      processed(state) {
        state.processing = false;
      },
      setSort(state, sort = {}) {
        state.sort = {
          ...sort,
          direction:
            state.sort.field === sort.field && state.sort.direction === "asc"
              ? "desc"
              : "asc"
        };
      }
    },
    actions: {
      index(
        { commit, getters },
        { page = undefined, filters = [], sort = {}, panel = "" }
      ) {
        commit("loading");

        return new Promise((resolve, reject) => {
          this.$axios
            .get(`/${name}`, {
              params: {
                page,
                perPage: getters.perPage,
                filters: JSON.stringify(filters),
                sorts:
                  Object.keys(sort).length > 0
                    ? JSON.stringify([sort])
                    : undefined,
                panel
              }
            })
            .then((response) => {
              commit("succeeded", {
                entities: response.data.data,
                total: response.data.total,
                page: page
              });

              resolve();
            })
            .catch((error) => {
              commit("failed", { error });

              reject(error);
            });
        });
      },
      destroy({ commit }, { pk }) {
        const store = this;

        commit("processing");

        return new Promise((resolve, reject) => {
          store.$axios // axios
            .delete(`/${name}/${pk}`) // delete
            .then(() => {
              commit("processed");

              setOptionRefreshTrigger(name, store);

              utility.$message({
                type: "success",
                message: "削除しました",
                showClose: true,
                customClass: "el-message-content-position"
              });

              resolve();
            })
            .catch((error) => {
              commit("processed");
              commit("failed", { error });

              reject(error);
            });
        });
      }
    },
    getters: {
      entities(state) {
        return state.entities || [];
      },
      total(state) {
        return state.total || 0;
      },
      page(state) {
        return state.page || 1;
      },
      perPage(state) {
        return state.perPage || undefined;
      },
      pageLength(state) {
        if (state.total > 0) {
          return Math.ceil(state.total / state.perPage);
        } else {
          return 0;
        }
      },
      sort(state) {
        return state.sort || {};
      },
      isLoading(state) {
        return state.loading;
      },
      isLoaded(state) {
        return state.loaded;
      },
      isProcessing(state) {
        return state.processing;
      },
      error(state) {
        return state.error;
      }
    }
  };
};

export const entity = (name, initialEntity) => {
  return {
    namespaced: true,
    state: {
      entity: null,
      loading: false,
      loaded: false,
      processing: false,
      error: false,
      errorMessages: {},
      fromConfirm: false
    },
    mutations: {
      initialize(state) {
        state.entity = { ...initialEntity };
        state.loading = false;
        state.loaded = false;
        state.processing = false;
        state.error = false;
        state.errorMessages = [];
        state.fromConfirm = false;
      },
      loading(state) {
        state.loading = true;
        state.loaded = false;
      },
      succeeded(state, payload) {
        state.entity = payload.entity;
        state.loading = false;
        state.loaded = true;
        state.error = false;
        state.errorMessages = [];
      },
      confirm(state, payload) {
        state.entity = {
          ...state.entity,
          ...payload.inputEntity
        };
        state.fromConfirm = false;
      },
      backFromConfirm(state) {
        state.fromConfirm = true;
      },
      failed(state, { error }) {
        state.loading = false;
        state.loaded = true;
        state.fromConfirm = false;
        state.error = true;

        let status = null;
        let message = "";
        let showDialog = null;
        let forceRedirect = null;
        let forceDone = null;

        if (error.response) {
          status = error.response.status;
          const { data } = error.response;
          showDialog = data.showDialog;
          forceRedirect = data.forceRedirect;
          forceDone = data.forceDone;
          message = data.message;
          if (data.message_en) {
            message += " " + data.message_en;
          }
        } else {
          status = error.status || null;
          this.commit("done/displayableTrue");
          router.push({
            name: "done-index",
            params: {
              message:
                "処理を正常に終了しませんでした。マイページにサインインの上、再度お手続きください。 The process did not succeed. Please try again from the sign-in."
            }
          });
          return;
        }

        switch (status) {
          case 400:
            if (
              showDialog === true &&
              forceRedirect === true &&
              forceDone === false
            ) {
              // ダイアログを表示し、OKクリックでHOMEににリダイレクト
              utility
                .$alert(message, "エラー (Error)", {
                  confirmButtonText: "HOME",
                  closeOnClickModal: false,
                  closeOnPressEscape: false,
                  showClose: false,
                  type: "error"
                })
                .then(() => {
                  window.location.href = "https://jam-id.jp";
                });
            } else if (
              showDialog === false &&
              forceRedirect === true &&
              forceDone === false
            ) {
              // ダイアログを出させず、HOMEに強制的にリダイレクト
              utility.$message({
                type: "error",
                message,
                showClose: true,
                customClass: "el-message-content-position",
                onClose: () => {
                  window.location.href = "https://jam-id.jp";
                }
              });
            } else if (
              showDialog === false &&
              forceRedirect === false &&
              forceDone === false
            ) {
              // ページ内に赤い文字を出力（遷移なし）
              state.errorMessages = { message };
            } else {
              // その他
              this.commit("done/displayableTrue");
              router.push({
                name: "done-index",
                params: {
                  message:
                    "処理を正常に終了しませんでした。マイページにサインインの上、再度お手続きください。 The process did not succeed. Please try again from the sign-in."
                }
              });
            }
            break;

          case 500:
            if (
              showDialog === false &&
              forceRedirect === false &&
              forceDone === false
            ) {
              // ページ内に赤い文字を出力（遷移なし）
              state.errorMessages = { message };
            } else if (
              showDialog === false &&
              forceDone === true &&
              forceRedirect === false
            ) {
              // /doneに遷移
              this.commit("done/displayableTrue");
              router.push({
                name: "done-index",
                params: { message }
              });
            } else {
              // その他
              this.commit("done/displayableTrue");
              router.push({
                name: "done-index",
                params: {
                  message:
                    "処理を正常に終了しませんでした。マイページにサインインの上、再度お手続きください。 The process did not succeed. Please try again from the sign-in."
                }
              });
            }
            break;
          case 503:
            window.location.href =
              process.env.VUE_APP_BASE_URL + "/under-maintenance";
            break;

          default:
            this.commit("done/displayableTrue");
            router.push({
              name: "done-index",
              params: {
                message:
                  "処理を正常に終了しませんでした。マイページにサインインの上、再度お手続きください。 The process did not succeed. Please try again from the sign-in."
              }
            });
            break;
        }
      },
      processing(state) {
        state.processing = true;
      },
      processed(state) {
        state.processing = false;
      }
    },
    actions: {
      show({ commit }, { pk }) {
        commit("loading");

        return new Promise((resolve, reject) => {
          this.$axios // axios
            .get(`/${name}/${pk}`) // get
            .then((response) => {
              commit("succeeded", {
                entity: response.data.data
              });

              resolve();
            })
            .catch((error) => {
              commit("failed", { error });

              reject(error);
            });
        });
      },
      store({ commit }, { storedEntity, path = "", formKeys = [] }) {
        const store = this;

        commit("processing");
        let url = `/${name}`;
        if (path) {
          url += `/${path}`;
        }

        return new Promise((resolve, reject) => {
          store.$axios
            .post(url, storedEntity)
            .then((response) => {
              commit("processed");

              setOptionRefreshTrigger(name, store);

              clearDirty(commit, formKeys);

              utility.$message({
                type: "success",
                message: "登録しました",
                showClose: true,
                customClass: "el-message-content-position"
              });

              resolve(response);
            })
            .catch((error) => {
              commit("processed");
              commit("failed", { error });

              reject(error);
            });
        });
      },
      update({ commit }, { pk, path = "", updatedEntity, formKeys = [] }) {
        const store = this;

        commit("processing");
        let url = `/${name}/${pk}`;
        if (path) {
          url += `/${path}`;
        }

        return new Promise((resolve, reject) => {
          store.$axios
            .put(url, updatedEntity)
            .then(() => {
              commit("processed");

              setOptionRefreshTrigger(name, store);

              clearDirty(commit, formKeys);

              utility.$message({
                type: "success",
                message: "更新しました",
                showClose: true,
                customClass: "el-message-content-position"
              });

              resolve();
            })
            .catch((error) => {
              commit("processed");
              commit("failed", { error });

              reject(error);
            });
        });
      },
      destroy({ commit }, { pk, destroyEntity = undefined }) {
        const store = this;

        commit("processing");

        return new Promise((resolve, reject) => {
          store.$axios // axios
            .delete(`/${name}/${pk}`, {
              params: {
                ...destroyEntity
              }
            }) // delete
            .then(() => {
              commit("processed");

              setOptionRefreshTrigger(name, store);

              utility.$message({
                type: "success",
                message: "削除しました",
                showClose: true,
                customClass: "el-message-content-position"
              });

              resolve();
            })
            .catch((error) => {
              commit("processed");
              commit("failed", { error });

              reject(error);
            });
        });
      },
      confirm({ commit }, { inputEntity, formKeys = [] }) {
        const store = this;

        commit("processing");

        return new Promise((resolve) => {
          commit("processed");
          commit("confirm", {
            inputEntity
          });

          setOptionRefreshTrigger(name, store);

          resolve();
        });
      },
      backFromConfirm({ commit }) {
        commit("processing");

        return new Promise((resolve) => {
          commit("processed");

          clearDirty(commit, []);

          commit("backFromConfirm");

          resolve();
        });
      }
    },
    getters: {
      initialEntity() {
        return { ...initialEntity };
      },
      entity(state) {
        return state.entity || {};
      },
      isLoading(state) {
        return state.loading;
      },
      isLoaded(state) {
        return state.loaded;
      },
      isProcessing(state) {
        return state.processing;
      },
      error(state) {
        return state.error;
      },
      errorMessages(state) {
        return state.errorMessages || {};
      },
      isFromConfirm(state) {
        return state.fromConfirm;
      }
    }
  };
};

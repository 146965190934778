<template>
  <v-dialog
    v-model="open"
    persistent
    no-click-animation
    width="500px"
    :content-class="$style['dialog']"
  >
    <v-card>
      <v-overlay :value="processing">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-text class="pa-6" :class="$style['dialog-content']">
        <p :class="$style['dialog-content-message']">
          認証できませんでした。再ログインしてください。
        </p>
        <form :class="$style['dialog-content-form']">
          <v-text-field
            v-model="loginId"
            label="ID"
            clearable
            prepend-icon="mdi-account"
          ></v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            label="パスワード"
            clearable
            prepend-icon="mdi-lock"
          ></v-text-field>
          <p v-if="isError" :class="$style['dialog-content-form-error']">
            ID/パスワードの組合せが正しくありません。
          </p>
        </form>
        <v-btn color="primary" block dark @click="login">ログイン</v-btn>
      </v-card-text>
      <v-divider />
      <v-card-text class="px-6 py-4">
        <v-btn color="secondary" block dark @click="logout">ログアウト</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      loginId: "",
      password: ""
    };
  },
  computed: {
    open() {
      return this.$store.getters["auth/reAuthorizing"];
    },
    isError() {
      return this.$store.getters["auth/isError"];
    },
    processing() {
      return this.$store.getters[`auth/processing`];
    }
  },
  methods: {
    login() {
      this.$store
        .dispatch("auth/login", {
          loginId: this.loginId,
          password: this.password
        })
        .then(() => {
          this.$store.commit("auth/reAuthorized");
        })
        .catch(() => {});
    },
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>

<style module lang="scss">
.dialog {
  .dialog-content {
    .dialog-content-message {
      text-align: center;
      color: #ff1100;
      margin-bottom: 20px;
      font-size: $font-size-root;
    }

    .dialog-content-form {
      .dialog-content-form-error {
        padding-bottom: 16px;
        color: #ff1100;
        text-align: center;
      }
    }
  }
}
</style>

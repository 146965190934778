import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/entries/front/store";
import utility from "@/utility";

import Main from "@/entries/front/views/main";
import Auth from "@/entries/front/views/auth";

Vue.use(VueRouter);

const loadView = (view) => {
  return () =>
    import(/* webpackChunkName: "[view]" */ `@/entries/front/views/${view}`);
};

const routes = [
  {
    path: "",
    name: "mypage-index",
    component: loadView("mypage/mypage-index"),
    meta: {
      isPublic: true,
      title: "マイページTOP"
    }
  },
  {
    path: "inquiry",
    name: "inquiry-index",
    component: loadView("inquiry/inquiry-index"),
    meta: {
      isPublic: true,
      title: "お問い合わせ"
    }
  },
  {
    path: "account",
    name: "account-index",
    component: loadView("account/account-index"),
    meta: {
      isPublic: true,
      title: "アカウント情報の参照"
    }
  },
  {
    path: "account/name",
    name: "account-name-edit",
    component: loadView("account/edit/account-name"),
    meta: {
      isPublic: true,
      title: "ユーザ名の変更"
    }
  },
  {
    path: "account/email",
    name: "account-email-edit",
    component: loadView("account/edit/account-email"),
    meta: {
      isPublic: true,
      title: "メールアドレスの変更"
    }
  },
  {
    path: "account/auth-code-email",
    name: "account-auth-code-email",
    component: loadView("account/edit/account-email-auth-code"),
    meta: {
      isPublic: true,
      title: "認証コード入力"
    }
  },
  {
    path: "account/phone",
    name: "account-phone-edit",
    component: loadView("account/edit/account-phone"),
    meta: {
      isPublic: true,
      title: "電話番号の変更"
    }
  },
  {
    path: "account/auth-code-phone",
    name: "account-auth-code-phone",
    component: loadView("account/edit/account-phone-auth-code"),
    meta: {
      isPublic: true,
      title: "認証コード入力"
    }
  },
  {
    path: "account/password",
    name: "account-password-edit",
    component: loadView("account/edit/account-password"),
    meta: {
      isPublic: true,
      title: "パスワードの変更"
    }
  },
  {
    path: "done",
    name: "done-index",
    component: loadView("done/done-index"),
    meta: {
      isPublic: true,
      title: "完了"
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters["done/isDisplayable"]) {
        next();
        store.commit("done/displayableFalse");
      } else {
        window.location.href = "https://jam-id.jp";
      }
    }
  },
  {
    path: "error400",
    name: "error-400",
    component: loadView("error/400"),
    meta: {
      isPublic: true,
      title: ""
    }
  },
  {
    path: "*",
    name: "notFound",
    component: loadView("error/404"),
    meta: {
      isPublic: true,
      title: ""
    }
  }
];

const signinRoutes = [
  {
    path: "",
    name: "signin",
    component: loadView("auth/signin"),
    meta: {
      isPublic: true,
      title: "ログイン"
    }
  }
];

const signupRoutes = [
  {
    path: "",
    name: "signup",
    component: loadView("auth/signup/signup"),
    meta: {
      isPublic: true,
      title: "サインアップ"
    }
  },
  {
    path: "/what-jamid-is",
    name: "what-jamid-is",
    component: loadView("auth/what-jamid-is"),
    meta: {
      isPublic: true,
      title: "jaM idとは"
    }
  },
  {
    path: "/signup-social",
    name: "signup-social",
    component: loadView("auth/signup/signup-social"),
    meta: {
      isPublic: true,
      title: "新規アカウント登録"
    }
  },
  {
    path: "complete",
    name: "signup-complete",
    component: loadView("auth/signup/signup-complete"),
    meta: {
      isPublic: true,
      title: "新規アカウント登録完了"
    }
  },
  {
    path: "terms-of-service",
    name: "signup-terms-of-service",
    component: loadView("auth/signup/signup-terms-of-service"),
    meta: {
      isPublic: true,
      title: "利用規約同意"
    }
  },
  {
    path: "privacy-policy",
    name: "signup-privacy-policy",
    component: loadView("auth/signup/signup-privacy-policy"),
    meta: {
      isPublic: true,
      title: "プライバシーポリシー"
    }
  },
  {
    path: "phone",
    name: "signup-phone",
    component: loadView("auth/signup/signup-phone"),
    meta: {
      isPublic: true,
      title: "電話番号入力"
    }
  },
  {
    path: "auth-code-phone",
    name: "signup-auth-code-phone",
    component: loadView("auth/signup/signup-auth-code"),
    meta: {
      isPublic: true,
      title: "認証コード入力",
      type: "phone"
    }
  },
  {
    path: "auth-code-email",
    name: "signup-auth-code-email",
    component: loadView("auth/signup/signup-auth-code"),
    meta: {
      isPublic: true,
      title: "認証コード入力",
      type: "email"
    }
  },
  {
    path: "authorization",
    name: "signup-authorization",
    component: loadView("auth/signup/signup-authorization"),
    meta: {
      isPublic: true,
      title: "認可"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Main,
      children: routes
    }
  ]
});

let isFirstRouting = true;

/**
 * グローバルガード
 */
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | JAM ID" : "JAM ID";

  // 認証が必要な画面に認証されていない状態で遷移した場合，
  //  ・初回ロード時はログイン画面へリダイレクトします(画面がないので再認証フォームを表示できない)
  //  ・それ以外の遷移では再認証を行います．
  if (!to.matched[1].meta.isPublic && !store.getters["auth/authenticated"]()) {
    if (isFirstRouting) {
      next({
        name: "signin"
      });
    } else {
      store.commit("auth/reAuthorize");
    }

    isFirstRouting = false;
    return;
  }

  // 確認画面でリロードした場合の対応
  if (to.path.match(/^.+\/confirm$/)) {
    if (isFirstRouting) {
      isFirstRouting = false;
      const segments = to.path.split("/");
      segments.pop();
      next(segments.join("/"));
      return;
    }
  }

  // フォームが編集状態の場合ダイアログを表示します
  if (
    store.state.form &&
    store.getters["form/isDirty"]("base-form") &&
    !from.path.match(/^.+\/confirm$/) &&
    !to.path.match(/^.+\/confirm$/)
  ) {
    utility
      .$confirm("入力内容を破棄します。よろしいですか。", "確認", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: false
      })
      .then(() => {
        next();
        store.commit("form/clearDirty");
      })
      .catch(() => {});
    isFirstRouting = false;
    return;
  }

  isFirstRouting = false;
  next();
});

/**
 * グローバルAfterフック
 */
router.afterEach(() => {
  if (store.state.form) {
    store.commit("form/clearDirty");
  }
});

export default router;

<template>
  <v-app>
    <v-main :class="$style.auth">
      <router-view />
    </v-main>
    <template v-if="hasFooter">
      <SigninFooter />
    </template>
    <ReauthenticationDialog />
  </v-app>
</template>

<script>
import AppHeader from "@/entries/front/components/app/header";
import ReauthenticationDialog from "@/entries/front/components/dialogs/reauthentication";
import SigninFooter from "@/entries/front/components/app/signin-footer";

export default {
  components: {
    AppHeader,
    SigninFooter,
    ReauthenticationDialog
  },
  computed: {
    hasFooter() {
      const current = this.$router.history.current;
      return current.name === "signup" || current.name === "signin";
    }
  },
  created() {}
};
</script>

<style module lang="scss">
.auth {
  background-color: #f3f5f8;
}
</style>

<style lang="scss">
.img-btn {
  cursor: pointer !important;
  &:hover {
    opacity: 0.8 !important;
  }
}
</style>
